import React from "react";

import aboutImage from "../../img/about.jpg";
import dayaMobileApp from "../../img/daya-mobile-app.png";

const HomePage = () => {
  return (
    <>
      {/* <div class="carousel">
        <div class="container-fluid">
          <div class="owl-carousel">
            <div class="carousel-item">
              <div class="carousel-img">
                <img src={corouselImage} alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="herosection">
        <div class="herosection-text">
          <h1>Transform Lives with Every Contribution</h1>
          <p>
            Join our community of changemakers. Empower those in need by
            donating directly and making a real difference today.
          </p>
          <div class="carousel-btn">
            <a class="btn btn-custom" href="#about">
              Learn More
            </a>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="videoModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive-item"
                  src=""
                  id="video"
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about" id="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img src={aboutImage} alt="about" class="about-img" />
            </div>
            <div class="col-lg-6">
              <div class="section-header">
                <p>Learn About Us</p>
                <h2>Empowering Change, One Donation at a Time</h2>
              </div>
              <div class="about-tab">
                <ul class="nav nav-pills nav-justified">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      data-toggle="pill"
                      href="#tab-content-1"
                    >
                      About
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="pill"
                      href="#tab-content-2"
                    >
                      Mission
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="pill"
                      href="#tab-content-3"
                    >
                      Vision
                    </a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div id="tab-content-1" class="container tab-pane active">
                    We are dedicated to empowering individuals and communities
                    by providing a platform for raising funds to address various
                    challenges. Our app enables users to request financial
                    assistance and receive direct donations from other users to
                    their bank accounts. We facilitate this process with
                    transparency and efficiency, earning a commission only after
                    each campaign successfully reaches its goal. Our goal is to
                    create a seamless and supportive environment for both donors
                    and recipients, fostering a community of care and
                    generosity.
                  </div>
                  <div id="tab-content-2" class="container tab-pane fade">
                    Our vision is to build a world where everyone has access to
                    the financial support they need during difficult times. We
                    strive to create a trusted and effective platform that
                    bridges the gap between those in need and those willing to
                    help, empowering individuals to overcome their challenges
                    and achieve a brighter future.
                  </div>
                  <div id="tab-content-3" class="container tab-pane fade">
                    Our mission is to provide a user-friendly and secure
                    platform that connects people seeking financial assistance
                    with generous donors. We aim to facilitate direct bank
                    account transfers to ensure that help reaches those who need
                    it most. By earning a commission only after successful
                    campaigns, we align our success with the success of our
                    users, reinforcing our commitment to their well-being and
                    the positive impact we strive to achieve.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="service" id="service">
        <div class="container">
          <div class="section-header text-center">
            <p>What We Do?</p>
            <h2>We believe that we can save more lifes with you</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div class="service-icon">
                  <i class="flaticon-diet"></i>
                </div>
                <div class="service-text">
                  <h3>Empower Fundraisers</h3>
                  <p>
                    We enable users to create campaigns for personal causes and
                    receive direct donations quickly.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div class="service-icon">
                  <i class="flaticon-water"></i>
                </div>
                <div class="service-text">
                  <h3>Facilitate Direct Donations</h3>
                  <p>
                    Our app allows donors to transfer funds directly to
                    recipients' bank accounts securely.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div class="service-icon">
                  <i class="flaticon-healthcare"></i>
                </div>
                <div class="service-text">
                  <h3>Support Community Initiatives</h3>
                  <p>
                    We help communities raise funds for projects, fostering
                    growth and positive change.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div class="service-icon">
                  <i class="flaticon-education"></i>
                </div>
                <div class="service-text">
                  <h3>Streamline Campaign Management</h3>
                  <p>
                    Our platform simplifies campaign creation, tracking, and
                    management for all users.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div class="service-icon">
                  <i class="flaticon-home"></i>
                </div>
                <div class="service-text">
                  <h3>Ensure Transaction Security</h3>
                  <p>
                    We prioritize safety, using robust measures to protect user
                    data and financial transactions.
                  </p>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-6">
              <div class="service-item">
                <div class="service-icon">
                  <i class="flaticon-social-care"></i>
                </div>
                <div class="service-text">
                  <h3>Provide Transparent Commission</h3>
                  <p>
                    Our transparent commission model ensures that funds reach
                    recipients efficiently and effectively.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div class="event" id="our-app">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex align-items-center">
              <div class="section-header">
                <p>Mobile App Is Coming Soon...</p>
                <h2>Get Ready: Our App Launches Soon!</h2>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="event-item">
                <img src={dayaMobileApp} alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="donate background-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7">
              <div class="donate-content">
                <div class="section-header">
                  <p>Donate Now</p>
                  <h2>Empower Change, One Donation at a Time</h2>
                </div>
                <div class="donate-text">
                  <p>
                    Join us in making a difference. With each donation, you help
                    transform lives and build a brighter future. Together, we
                    can create lasting change, one contribution at a time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="testimonial">
        <div class="container">
          <div class="section-header text-center">
            <p>Testimonial</p>
            <h2>What people are talking about our charity activities</h2>
          </div>
          <div class="owl-carousel testimonials-carousel">
            <div class="testimonial-item">
              <div class="testimonial-profile">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv71bILFx0kuyYzdSR9me90DT6K8XG93DNNA&s"
                  alt="Image"
                />
                <div class="testimonial-name">
                  <h3>Sebastin John</h3>
                </div>
              </div>
              <div class="testimonial-text">
                <p>
                  I’m really thankful to DayaLifeline for helping my family when
                  we needed it most. Setting up a fundraising campaign was easy,
                  and the team was there to guide me every step of the way. We
                  reached our goal quickly and got the help we needed. I highly
                  recommend DayaLifeline to anyone who needs support!
                </p>
              </div>
            </div>
            <div class="testimonial-item">
              <div class="testimonial-profile">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv71bILFx0kuyYzdSR9me90DT6K8XG93DNNA&s"
                  alt="Image"
                />
                <div class="testimonial-name">
                  <h3>Shiju</h3>
                </div>
              </div>
              <div class="testimonial-text">
                <p>
                  DayaLifeline was a big help during a tough time. The app is
                  easy to use, and it was simple to ask for help and connect
                  with donors. I felt supported throughout the process, and I’m
                  grateful for the help I received. They really care about
                  helping people.
                </p>
              </div>
            </div>
            <div class="testimonial-item">
              <div class="testimonial-profile">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv71bILFx0kuyYzdSR9me90DT6K8XG93DNNA&s"
                  alt="Image"
                />
                <div class="testimonial-name">
                  <h3>Vaishanav</h3>
                </div>
              </div>
              <div class="testimonial-text">
                <p>
                  ഞങ്ങളുടെ കുടുംബത്തിന് ഏറ്റവും ആവശ്യമുള്ള സമയത്ത് സഹായിച്ച
                  ദയാലൈഫ്‌ലൈൻ ടീമിന് ഞാൻ വളരെ നന്ദിയുണ്ട്. ഫണ്ട്രെയ്സിംഗ്
                  ക്യാമ്പെയ്ൻ ആരംഭിക്കുന്നത് എളുപ്പമായിരുന്നു, ഓരോ ഘട്ടത്തിലും
                  ടീം എന്നെ നയിക്കാനുണ്ടായിരുന്നു. ഞങ്ങൾക്ക് ആവശ്യമായ സഹായം
                  ലഭിക്കാൻ ഞങ്ങൾ ഞങ്ങളുടെ ലക്ഷ്യത്തിലെത്തി. സഹായം ആവശ്യമുള്ള
                  എല്ലാവർക്കും ഞാൻ ദയാലൈഫ്‌ലൈൻ നേരത്തെ ശുപാർശ ചെയ്യുന്നു!
                </p>
              </div>
            </div>
            <div class="testimonial-item">
              <div class="testimonial-profile">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv71bILFx0kuyYzdSR9me90DT6K8XG93DNNA&s"
                  alt="Image"
                />
                <div class="testimonial-name">
                  <h3>Shibu</h3>
                </div>
              </div>
              <div class="testimonial-text">
                <p>
                  വല്ലാതെ ബുദ്ധിമുട്ടായ സമയത്ത് ദയാലൈഫ്‌ലൈൻ വലിയ സഹായം നൽകി.
                  ആപ്പിന്റെ ഉപയോഗം എളുപ്പമാണ്, സഹായത്തിനായി അഭ്യർത്ഥിക്കുകയും
                  ദാതാക്കളുമായി ബന്ധപ്പെടുകയും ചെയ്യുന്നത് എളുപ്പമാണ്. എന്റെ
                  ആവശ്യങ്ങൾക്കായി അവർ വലിയ പിന്തുണ നൽകുകയും എന്റെ ജീവിതം വളരെ
                  മെച്ചമാക്കുകയും ചെയ്തു. അവർക്കായി ഞങ്ങൾ വളരെ നന്ദിയുള്ളവരാണ്.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container">
          <div class="section-header text-center">
            <p>Get In Touch</p>
            <h2>Contact for any query</h2>
          </div>
          {/* <div class="contact-img">
            <img src="img/contact.jpg" alt="Image" />
          </div> */}
          <div class="contact-form">
            <div id="success"></div>
            <form name="sentMessage" id="contactForm" novalidate="novalidate">
              <div class="control-group">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Your Name"
                  required="required"
                  data-validation-required-message="Please enter your name"
                />
                <p class="help-block text-danger"></p>
              </div>
              <div class="control-group">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Your Email"
                  required="required"
                  data-validation-required-message="Please enter your email"
                />
                <p class="help-block text-danger"></p>
              </div>
              <div class="control-group">
                <input
                  type="text"
                  class="form-control"
                  id="subject"
                  placeholder="Subject"
                  required="required"
                  data-validation-required-message="Please enter a subject"
                />
                <p class="help-block text-danger"></p>
              </div>
              <div class="control-group">
                <textarea
                  class="form-control"
                  id="message"
                  placeholder="Message"
                  required="required"
                  data-validation-required-message="Please enter your message"
                ></textarea>
                <p class="help-block text-danger"></p>
              </div>
              <div>
                <button
                  class="btn btn-custom"
                  type="submit"
                  id="sendMessageButton"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
