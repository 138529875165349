import axios from "axios";
import { toast } from "react-toastify";

const unauthorizedCode = [400, 401, 403];

const API_BASE_URL = "https://backend.dayalifeline.com/api";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("token") || null;

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    errorMessageHandler(error.response.data);

    let notificationParam = {
      message: "",
    };

    // Remove token and redirect
    if (unauthorizedCode.includes(error.response.status)) {
      // notificationParam.message = 'Authentication Fail'
      // notificationParam.description = 'Please login again'
      // localStorage.removeItem(AUTH_TOKEN)
      // store.dispatch(signOutSuccess())
    }

    if (error.response.status === 404) {
      // notificationParam.message = 'Not Found'
    }

    if (error.response.status === 500) {
      // notificationParam.message = 'Internal Server Error'
    }

    if (error.response.status === 508) {
      // notificationParam.message = 'Time Out'
    }

    return Promise.reject(error);
  }
);

const errorMessageHandler = (error) => {
  console.log(error, "heyy");
  const errorsListGenerator = (errorList) => {
    return Object.entries(errorList).forEach(function ([key, value]) {
      return (
        <div>
          {key}: {value}
        </div>
      );
    });
  };

  if ("errors" in error) {
    if (Object.keys(error?.errors).length !== 0) {
      toast.error(error?.message || "Error Occured");
      // toast.error({
      //   message: error?.message || "Error Occured",
      //   description: () => <>{errorsListGenerator(error?.errors)}</>,
      // });
    } else {
      if (error?.message !== "Unauthenticated.") {
        toast.error(error?.message || "Error Occured");
      }

      //   toast.error({
      //     message: error?.message || "Error Occured",
      //   });
    }
  } else {
    if (error?.message && error?.message !== "Unauthenticated.") {
      toast.error(error?.message || "Error Occured");
    } else if (error?.error) {
      toast.error(error?.error);
    }

    // toast.error({
    //   message: error?.message || "Error Occured",
    // });
  }
};

export default service;
